 


function Photo(data) {

   

    return (<img className="photo"
        src={data.data.url }
        alt={data.data.alt}
    />
    
    )


    
}

export default Photo;

