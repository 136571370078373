 

let images = [{
    id: 0,
    name: 'Homenaje a la Neurocirug�a',
    description: 'Bien que Colvin soit principalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
    url: 'baguette.jpg',
    alt: 'Une statue en bronze de deux mains crois�es tenant d�licatement un cerveau humain du bout des doigts.'
},
{
    id: 1,
    name: 'test2',
    description: ' n � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
        url: 'src/assets/medias/svg/grains-cafe.jpg',
    alt: ' s�es tenant d�licatement un cerveau humain du bout des doigts.'
},
{
    id: 2,
    name: 'test3',
    description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
    url: '/static/media/logo-marais.jpg',
    alt: ' umain du bout des doigts.'
    },
    {
        id: 3,
        name: 'test 4',
        description: ' ncipalement conne pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
        url:  '/static/media/lion.jpg'  ,
        alt: ' umain du bout des doigts.'
    },
    {
        id: 4,
        name: 'test 5',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
        url: '/static/media/glitch.svg',
        alt: ' umain du bout des doigts.'
    },
    { 
        id: 5,
        name: 'test 6',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
        url:'/static/media/TCWS-2.svg',
        alt: ' umain du bout des doigts.'
    },
      {
        id: 6,
        name: 'test 7',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
          url: '/static/media/assemblage-barrieres.jpg',
        alt: ' umain du bout des doigts.'
    },
      {
        id: 7,
        name: 'test 6',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
          url: '/static/media/defonceuse-mortaises.jpg',
        alt: ' umain du bout des doigts.'
    },
      {
        id: 8,
        name: 'test 6',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
          url: '/static/media/montage-charpente.jpg',
        alt: ' umain du bout des doigts.'
    },
      {
        id: 9,
        name: 'test 6',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
          url: '/static/media/renovation-toiture.jpg',
        alt: ' umain du bout des doigts.'
    },
    {
        id: 10,
        name: 'test 6',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
        url: '/static/media/creation-pont.jpg',
        alt: ' umain du bout des doigts.'
    },   {
        id: 11,
        name: 'test 6',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
              url: '/static/media/terrassement-cheminement.jpg',
        alt: ' umain du bout des doigts.'
    }, {
        id: 12,
        name: 'test 6',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
        url: '/static/media/renovation-toiture.jpg',
        alt: ' umain du bout des doigts.'
    }, {
        id: 13,
        name: 'test 6',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
        url: '/static/media/renovation-toiture.jpg',
        alt: ' umain du bout des doigts.'
    }, {
        id: 14,
        name: 'test 6',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
              url: '/static/media/alignement-poteaux-cloture.jpg',
        alt: ' umain du bout des doigts.'
    }, {
        id: 15,
        name: 'test 6',
        description: ' ncipalement connue pour ses th�mes abstraits qui font allusion � des symboles pr�hispaniques, cette sculpture gigantesque, un hommage � la neurochirurgie, est l�une de ses pi�ces d�art public les plus reconnaissables.',
              url: '/static/media/decoupes-scie-table-tenons.jpg',
        alt: ' umain du bout des doigts.'
    }
 

];
 




export default images;
